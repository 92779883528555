import {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";

function HomeSlider() {
  const { bannerTotal } = useSelector((store) => store.BannerAll);


  return (
    <>
      <Swiper
        grabCursor={true}
        navigation={false}
        pagination={true}
        mousewheel={false}
        keyboard={true}
        effect={"fade"}
        modules={[
          EffectFade,
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          Autoplay,
        ]}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="homeSlider"
      >
        {bannerTotal.map((banner, index) => (
          <SwiperSlide key={index}>
            <div className="homeSliderImg">
              <img src={banner.DeskImg} alt="banner" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default HomeSlider;
