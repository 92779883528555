import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { contactPost } from "../../redux/contact/ContactSlice";

function Footer() {
  const dispatch = useDispatch();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [textarea, setTextarea] = useState("");

  const [contactsubmit, setContactsubmit] = useState({});

  const ContactSubmit = (e) => {
    e.preventDefault();

    const formData = {
      firstname: String(firstname),
      lastname: String(lastname),
      mobile: String(mobile),
      email: String(email),
      text: String(textarea),
    };
    console.log(formData);
    dispatch(contactPost(formData));
    // alert("successfully")
  };
  return (
    <>
      <footer>
        <div className="bd-footer__top black-bg pt-30 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="bd-footer__widget bd-footer__widget1 pl-50 pr-40 mb-60">
                  <img
                    src="/assets/img/logo/logo3.jpeg"
                    alt="Logo"
                    className="footLogo"
                  />
                  <div className="bd-footer__title">
                    <h4>Get In Touch</h4>
                  </div>
                  <div className="bd-footer__contact">
                    <ul>
                      <li style={{ color: "#fff" }}>Head Office</li>
                      <li>
                        <a
                          style={{ color: "#fff" }}
                          href="https://goo.gl/maps/sDL3ARYbYo6c3nns7"
                        >
                          Asian Institute of Safety Management
                        </a>
                      </li>
                      <li style={{ color: "#fff" }}>
                        19-4-274/1/C/2/A, Opp Masjid-e-Mumtaz, BNK Colony,
                        Tadban X Road, Near Nehru Zoological Park, NH 7,
                        Bahadurpura, Hyderabad - 500064, Telangana State, India.
                      </li>

                      <li>
                        <a style={{ color: "#fff" }} href="tel:+919346066705">
                          +91-9346066705
                        </a>
                      </li>
                      <li>
                        <a style={{ color: "#fff" }} href="tel:+918121066705">
                          +91-8121066705
                        </a>
                      </li>
                      <li>
                        <a style={{ color: "#fff" }} href="tel:04024472416">
                          040-24472416
                        </a>
                      </li>
                      <li>
                        <a href="mailto:info@aismtc.com">
                          <span
                            className="__cf_email__"
                            data-cfemail="98f6fdfdfcf0fdf4e8d8fde0f9f5e8f4fdb6fbf7f5"
                          >
                            info@aismtc.com
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="bd-footer__widget bd-footer__widget1 pl-35 mb-60">
                  <div className="bd-footer__title">
                    <h4>Other Pages</h4>
                  </div>
                  <div className="bd-footer__link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/aboutus">About Us</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/career">Career</Link>
                      </li>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="bd-footer__widget bd-footer__widget1 pl-10 ">
                  <div className="bd-footer__title">
                    <h4>Subscribe Us</h4>
                  </div>
                  <p className="mb-20">
                    Subscribe us &amp; receive our office &amp; update in your
                    inbox directly
                  </p>
                  <div className="bd-footer__form">
                    <input type="email" placeholder="Email address" />
                  </div>
                </div>
              </div> */}
              <div className="col-xl-5 col-lg-5 col-md-6">
                <div className="bd-contact__wrapper bd-contact__60 mb-60">
                  <div className="bd-section__title mb-50">
                    <span className="bd__subtitle text-light">Contact Us</span>
                  </div>
                  <div className="bd-contact__form">
                    <form onSubmit={ContactSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="bd-single__input mb-20">
                            <input
                              type="text"
                              placeholder="First Name"
                              className="form-control"
                              required
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-single__input mb-20">
                            <input
                              type="text"
                              placeholder=" Last Name"
                              className="form-control"
                              required
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-single__input mb-20">
                            <input
                              type="number"
                              placeholder="Phone Number"
                              className="form-control"
                              required
                              minLength={10}
                              maxLength={10}
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-single__input mb-20">
                            <input
                              type="email"
                              placeholder="Email Address"
                              className="form-control"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <textarea
                          class="form-control"
                          rows="3"
                          placeholder="Type Your massage here"
                          name="text"
                          value={textarea}
                            onChange={(e) => setTextarea(e.target.value)}
                        ></textarea>

                        <div className="col-xl-6">
                          <div className="bd-single__input text-center">
                            <br />
                            <button type="submit" className="theme-btn">
                              Submit <i className="fal fa-arrow-alt-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bd-footer__bottom pt-20 black-bg-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="bd-footer__copy-text mb-20">
                  {/* <p>
                    Design By
                    <a href="https://www.dexterous.in" target="_blank">
                      <b> Dexterous Technology </b>
                    </a>
                    © 2022
                  </p> */}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 d-none d-lg-block"></div>

              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="bd-footer-social mb-20">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/AISMTC" target="_blank">
                        <i className="fab fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/asianmanpowerservices/?hl=en"
                        target="_blank"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://in.linkedin.com/school/asian-institute-of-safety-management/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                  <ul class="menu" style={{ marginTop: "20px" }}>
                    <li class="share top">
                      <i class=" fas fa-reply"></i>
                      <ul class="submenu">
                        <li>
                          <a
                            href="https://www.facebook.com/AISMTC"
                            target="_blank"
                            class="facebook"
                          >
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://in.linkedin.com/school/asian-institute-of-safety-management/"
                            target="_blank"
                            class="linkedin"
                          >
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/asianmanpowerservices/?hl=en"
                            target="_blank"
                            class="instagram"
                          >
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul className="icon">
                    <li>
                      <div className="wp-icon">
                        <a href=" https://wa.me/918121066705" target="_blank">
                          <img
                            style={{ marginTop: "-110px" }}
                            src="/assets/img/whatsapp-icon.png"
                            alt
                          />
                        </a>
                      </div>
                    </li>
                    {/* <div className="wrapper">
            <div className="button">
              <div className="icon"><i className="fab fa-facebook=f"></i></div>
              <span className="text-white">Enquiry</span>
            </div>
       </div> */}
                    <li>
                      <div className="wp-icon">
                        <Link to="/contact">
                          <img src="/assets/img/massages.png" alt />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
