
import { Navigation, Pagination, Mousewheel, Keyboard,Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import "bootstrap/dist/css/bootstrap.min.css";

const NewSlider = () => {
  return (
    <>
    <Swiper
   grabCursor={true}
   navigation={false}
  //  pagination={true}
   mousewheel={false}
   keyboard={true}
  //  slidesPerView={6}
   spaceBetween={30}
   modules={[Navigation, Pagination, Mousewheel, Keyboard,Autoplay]}
   loop={true}
   autoplay={{
     delay:1500,
     disableOnInteraction : false
   }}
   breakpoints={{
    360: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    470: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    580: {
      slidesPerView: 2,
      spaceBetween: 4,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 4,
    },
    1080: {
      slidesPerView: 6,
      spaceBetween: 4,
    },
  }}
   className="ourteam mb-4 mx-3"
 >
    <SwiperSlide>
     <div className="outslidmain">
      <div className="imgteam">
      <img src="/assets/img/ourteam/1.jpeg" className="rounded-circle" width={180} height={170}  alt="invalid" />
      </div>
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/2.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/3.jpeg" width={180} height={170}   alt="team" className="rounded-circle" />
      </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/4.jpeg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/5.jpeg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/6.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
      
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/7.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
     
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/8.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/9.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/10.jpeg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/11.jfif" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/12.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/13.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/14.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="assets/img/ourteam/15.jpeg" width={180} height={170}   alt="team" className="rounded-circle" />
       /
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/16.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/17.jpg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/ourteam/18.jpeg" width={180} height={170}   alt="team" className="rounded-circle" />
       
     </div>
     </SwiperSlide>

 </Swiper>

 </>
  );
};
export default NewSlider;
