import React, { useState } from "react";
import { useSelector } from "react-redux";
import getRegisterByCertificateNo from "../redux/register/RegisterSlice";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";

function Alumini() {
  const [search, setSearch] = useState("");
  const [certificateData, setCertificate] = useState();

  const searchChange = (e) => {
    const certiSearch = e.target.value;
    setSearch(certiSearch);
  };
  const ViewSubmit = async (e) => {
    e.preventDefault();
    const url = `${Baseurl}/api/v1/registration/getbycertificateNo/${search}`;
    const resp = await axios.get(url);
    setCertificate(resp.data.register);
  };

  return (
    <>
      <section className="bd-cta__question pt-65">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="bd-cta__question-content text-center">
                <div className="bd-section__title mb-20">
                  <h2 className="bd__title"> Verify Certification </h2>
                </div>
                <p className="peramhfgfg">
                  We are here to assist learners and organisations with their
                  document authentication needs since we are aware of how
                  important document authentication is. Both candidates and
                  businesses may easily access information about a specific
                  candidate's certification.
                </p>
                <h5>
                  Get immediate verification by entering the certificate or
                  enrollment number.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-contact2 p-relative mb-35 mt-0">
        <div className="container">
          <div
            className="row justify-content-end wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="col-lg-10">
              <div className="bd-contact2__wrapper">
                <div className="bd-contact__wrapper">
                  <div className="bd-contact__form">
                    <form onSubmit={ViewSubmit}>
                      <div className="row claadfdg">
                        <div className="col-md-9">
                          <div className="bd-single__input mb-10">
                            <input
                              type="text"
                              style={{ border: "1px solid #000" }}
                              value={search}
                              onChange={(e) => searchChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="bd-single__input d-flex justify-content-center">
                            <button type="submit" className="theme-btn">
                              Search
                              <i className="fal fa-arrow-alt-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {certificateData ? (
        <>
          <section className="bd-contact2 p-relative mb-35 mt-0">
            <div className="container">
              <div
                className="row justify-content-center wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="row certificate">
                  <div className="outer-border">
                    <div className="inner-dotted-border">
                      <div className="CertifiNumber">
                        <span className="CertifiNumber1">
                          {" "}
                          Certificate no:- {certificateData.certificate}
                        </span>
                      </div>
                      <span className="certification">
                        Certificate of Completion
                        <br />
                      </span>
                      <span className="certify">
                        <i>This Certificate is awarded to</i>
                        <br />
                      </span>
                      <span className="name">
                        <i>
                          {certificateData.name.charAt(0).toUpperCase() +
                            certificateData.name.slice(1).toLowerCase()}
                        </i>
                      </span>
                      <br />
                      <span className="certifyfname">
                        <i>Son of :- {certificateData.fname}</i>
                      </span>
                      <br />
                      <span className="certify">
                        <i>has successfully completed the certification</i>
                      </span>
                      <br />
                      <br />
                      <span className="fs-30">
                        {certificateData.course.toUpperCase()}
                      </span>{" "}
                      <br />
                      <br />
                      <span className="fs-20">
                        <i>
                          with score of{" "}
                          <b>{certificateData.grade.toUpperCase()}</b>
                        </i>
                      </span>
                      <br />
                      <br />
                      <span className="certify">
                        <i>dated </i>
                      </span>
                      <span className="fs-30">
                        {" "}
                        <i>{certificateData.date}</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <> </>
      )}
    </>
  );
}

export default Alumini;
