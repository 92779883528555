import React from "react";
import { Link } from "react-router-dom";

function Iosh() {
  return (
    <>
      <section className="bd-case-details-area pt-40 pb-30">
        <div className="container">
          <div
            className="bd-case-details-wrapper wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="bd-case-thumb br-img-6 w-img">
              <img src="/assets/img/banner/sliderimg2.webp" alt="case" />
            </div>
          </div>
        </div>
      </section>
      <section className="bd-cta__question ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="bd-cta__question-content text-center">
                <p className="mb-30 peramhfgfg">
                  If you want to register yourself into the course, please
                  schedule a counselling appointment by contacting our learning
                  adviser. Our expert learning adviser will take you through the
                  whole registration to certification process in detail. And
                  advise you on the best options based on your qualifications
                  and experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-cta__question pb-20">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="bd-cta__question-content text-center">
                <div className="bd-cta__question-btn">
                  <Link className="theme-btn" to="/contact">
                    Contact Learning Advisor{" "}
                    <i className="fal fa-arrow-alt-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-cta__question ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="bd-cta__question-content text-center">
                <div className="bd-section__title mb-20">
                  <h2 className="bd__title">IOSH MS – Managing Safely </h2>
                </div>
                <p className="mb-35 peramhfgfg">
                  IOSH Managing Safely is the industry-leading, tried-and-true
                  health and safety programme for Safety professionals.
                  Developed to provide all safety professionals the assurance
                  and desire utilise knowledge in the workplace. By integrating
                  safety and health throughout the whole organisation, this
                  training will assist you raise the level of safety awareness
                  in your workplace, increase productivity, and improve your
                  reputation with organisation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Who can take this Certification?</h2>
            <br />
            <p>
              This certification is open to anybody in a working position who
              wishes to study the fundamentals of safety and health.
            </p>
          </div>
        </div>
      </section>
      <br />

      <section className=" section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
              <div className="content">
                <h3>
                  The below are some of the advantages of earning the IOSH MS –
                  Managing Safely:
                </h3>
              </div>
              <div className="accordion-list">
                <ul>
                  <li>
                    <span>01. </span>Obtain a role as a Safety Professional for
                    a Reputable Company.{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                    <span>02. </span>Meets the academic requirements for
                    Affiliate Membership of IOSH (Affiliate IOSH).{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                    <span>03. </span>Provides a solid foundation for the NEBOSH
                    International General Certificate or any other NQF Level 3
                    Qualification.{" "}
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <b className="fs-5">
            You can refer IOSH Official Website for additional information about
            the course, which includes information about the course's duration,
            syllabus, eligibility, and more.
          </b>
        </div>

        <div class="container m-4">
          <div class="col-md-12 text-center ">
            <a href="https://iosh.com/about-iosh/" class="theme-btn red me-5">
              Refer - IOSH Website <i class="fal fa-arrow-alt-right"></i>
            </a>
          </div>
        </div>
      </section>

      <section className="bd-faq pt-10 p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 wow fadeInUp" data-wow-delay=".3s">
              <div className="bd-faq__wrapper mb-60">
                <div className="accordion" id="">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="">
                      <button className="accordion-button">
                        Why AISM is the Best and Most Successful Learning
                        Partner:
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>• Affordable learning Package</p>
                        <br />
                        <p>
                          • An experienced career counsellor who can help you
                          not only with your studying but also with your future
                          plans.
                        </p>
                        <br />
                        <p>
                          • Authorized and experienced training professionals:
                          who are well qualified to make the learning process
                          smoother.{" "}
                        </p>
                        <br />
                        <p>
                          • Online / Virtual Classroom: Live Classes on Zoom /
                          Google Meet with Question answer Sessions.
                        </p>
                        <br />
                        <p>• Smart Classroom with all Learning Needs.</p>
                        <br />
                        <p>
                          • Flexible and adaptable learning time: Pre-recorded
                          training sessions are available at any time and from
                          any place. Comprehensive RRC Learning Material.
                          WhatsApp chat Support and many more
                        </p>
                        <br />
                        <p>
                          • Effective Method of Training: Providing you with the
                          most effective NEBOSH training possible. Individual
                          Learning, Group Learning, Mock Test, Subject Videos,
                          Presentations, Flashcards, and etc...{" "}
                        </p>
                        <br />
                        <p>
                          • Placements/Job Assistance: 1000’s of our Students
                          working as Safety professional all over the world. We
                          are a leading manpower recruitment consultant in India
                          and approved by the Ministry of External Affairs
                          (MEA), Government of India Registration No:
                          B-1070/TEL/PER/1000+/5/9402/2018 (Valid 12th Oct
                          2023).
                        </p>
                        <br />
                        <p>• 15 Years of Experience in the Industry. </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bd-work white-bg-2 pb-20"
        data-background="assets/img/work/work-bg.png"
      >
        <div className="bd-work__wrapper">
          <div className="container py-3">
            <div
              className="row justify-content-center wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div
                className="col-md-6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="bd-section__title text-center">
                  <h2 className=" btn-danger p-3">
                    Choose the best Education and Profession in one place
                  </h2>
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="bd-section__title  text-left cource-list">
                  <ul>
                    <li>
                      High Quality Training with Practical Activities Expert
                      Trainer with International Experience
                    </li>
                    <li>Smart Classroom | High Standard Syllabus </li>
                    <li>Interactive and practical Learning Experience</li>
                    <li>Job Ready – 100% Placement Support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="bd-work__item-wrapper wow fadeInUp"
              data-wow-delay=".5s"
            ></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Iosh;
