import React from "react";

function Directoremass() {
  return (
    <>
      <section className="bd-details__area bd-team__details pt-120 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7 order-lg-2">
              <div className="bd-details__content bd-details__content-padd mb-30">
                <div
                  className="bd-team__biodata wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="bd-team__thum mb-50 w-img d-block d-lg-none br-img-6">
                    <img src="/assets/img/banner/directmass.jpg" alt="team image" />
                  </div>
                  <div className="bd-team__d-name mb-25">
                    <h4 className="bd__title-xs mb-10">Director’s Message</h4>
                    <span className="bd-team__designation">
                      “With an honest heart and continuous effort, anyone may do
                      anything they desire.”
                    </span>
                  </div>
                  {/* <h3 className="bd__title-xxs mb-15">My Biography</h3> */}
                  <p className="mb-35 directmass">
                    It is a true pleasure for me to share some words with you.
                    Let me start by congratulating you on making the decision to
                    pursue a career in health and safety education. You will be
                    prepared to utilize the knowledge and skills you have
                    acquired.
                  </p>
                  <p className="mb-35 directmass">
                    The purpose of safety programs is not just to produce
                    certified safety professionals but also to create a safe and
                    accident-free workplace environment worldwide. We at the
                    Asian Institute of Safety Management make sure to establish
                    a welcoming learning atmosphere while increasing student
                    enough opportunity to grow personally and socially.
                  </p>
                  <p className="mb-35 directmass">
                    Our tutors are highly skilled, knowledgeable and passionate
                    about teaching, which helps them to bring out the best in
                    both themselves and their students.
                  </p>
                  <p className="mb-35 directmass">
                    Asian Institute of Safety Management is not the ordinary
                    institution; we encourage students to overcome difficulties
                    and challenges along the way to success in order to expand
                    their potential. We succeed thanks to our unwavering faith
                    in the capabilities of our tutors and students. The
                    management and staff of the Institution are devoted to
                    working constantly to make learning for our students an
                    engaging and informative experience.
                  </p>
                  <p className="mb-35 directmass">
                    Every one of us who succeeds does so as a result of someone
                    being there to guide the way, and we are one of those.
                  </p>
                  <p className="mb-35 directmass">
                    May we keep establishing in our students the belief that
                    they are capable of conquering the world!
                  </p>
                  <div className="direcdetail f-right">
<p className=" text-black" style={{fontWeight:"bold"}}>MOHAMMED ABDUL SAMAY SAYEED</p>
<p>Managing Director,</p>
<p>Asian Institute of Safety management</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 order-lg-1">
              <div className="bd-details-sidebar mb-60">
                <div
                  className="sidebar__widget w-img br-img-6 border-radius-6 mb-60 p-0 d-none d-lg-block wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <img src="assets/img/banner/directmass.jpg" alt="team image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Directoremass;
