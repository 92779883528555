import React from "react";
import ServiceSlider from "../Compund/Home/ServiceSlider";

function Services() {
  return (
    <>
      <ServiceSlider />
      <section className="bd-contact-faq">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="bd-section__title mb-50  ">
                <h2 className="bd__title-sm text-center">services</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay=".3s">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6">
                <img
                  src="assets/img/services/service-1.jpeg"
                  className="w-100"
                  alt="team image"
                />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <img
                  src="assets/img/services/service-2.jpeg"
                  className="w-100"
                  alt="team image"
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="bd-faq__wrapper bd-faq__padd2 mb-60">
                <div className="accordion" id="accordionExample">
                  <div className=" ">
                    <h3 className="  mt-2" style={{ color: "#de4259" }}>
                      Health and Safety Course Provider
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          The Government of AP has recognized Asian Institute of
                          Safety Management for delivering numerous diploma and
                          postgraduate diploma level courses. From entry-level
                          to advanced programs, we provide a wide range of
                          health and safety courses. Thousands of students
                          registered and had been employed by multinational
                          companies all around the world.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 className="  mt-2" style={{ color: "#de4259" }}>
                      International Course Provider
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          Nowadays, every multinational company wants its
                          employees to have credentials from internationally
                          renowned training providers. Therefore, a number of
                          international course providers have accredited Asian
                          Institute of Safety Management to provide their
                          courses in India. for instance, NEBOSH, IOSH, MEDIC,
                          etc. We are one of the India’s top international
                          course providers in terms of educational quality and
                          graduation rate.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 style={{ color: "#de4259" }} className="  mt-2">
                      Class Room Trainning
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          We provide both traditional in-person classroom
                          training sessions and virtual online classroom
                          training sessions. We have a state of art training
                          class rooms which had projector and online testing
                          skills for the candidate besides teaching subjects we
                          handle personality development sessions for the
                          candidates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 className="  mt-2" style={{ color: "#de4259" }}>
                      Online Course Provider
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          In addition to classroom training, we also have an
                          online learning environment where students can
                          register in courses and learn by watching recorded
                          training sessions, question and answer sessions, a
                          library of course materials, a library of additional
                          materials, assignments, quizzes, mock exams, and more.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 className="mt-2" style={{ color: "#de4259" }}>
                      On Site Training Provider
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          We collaborate with companies in the construction
                          industry and provide on-site health and safety
                          training for staff. We offer short-term awareness
                          trainings on a variety of topics, including work at
                          height safety, confined space safety, first aid, risk
                          assessment, and hazard management and more.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <img
                        src="assets/img/services/service-5.jpeg"
                        className="w-100"
                        alt="team image"
                      />
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <img
                        src="assets/img/services/service-6.jpeg"
                        className="w-100"
                        alt="team image"
                      />
                    </div>
                  </div>
                  <div className="">
                    <h3 className="  mt-2" style={{ color: "#de4259" }}>
                      Offshore HSE Course Provider
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          Employees in the offshore construction sector are
                          required to complete obligatory lifesaving training.
                          such as BOISET Courses, OPITO Approved Courses, and
                          Sea Survival Trainings. Therefore we assist them in
                          obtaining the certifications and complete the
                          trainings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 className="  mt-2" style={{ color: "#de4259" }}>
                      Oil and Gas HSE Course Provider
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          In addition to industrial and construction-related
                          diploma programmes in health and safety We also
                          provide a comprehensive choice of Certificate and
                          Diploma level Oil and Gas Safety Courses at the
                          national and international levels, which enable the
                          students in pursuing careers in the Oil and Gas
                          Construction Industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 className="  mt-2" style={{ color: "#de4259" }}>
                      RISK ASSESSMENT
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          We facilitate risk assessment for many companies,
                          including those in the construction, medical,
                          biological, etc. Along with risk assessment, we offer
                          a hands-on workshop on a specific job assessment.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" ">
                    <h3 style={{ color: "#de4259" }} className="  mt-2">
                      COMPETANT ADVISORY BOARD
                    </h3>

                    <div>
                      <div className=" ">
                        <p>
                          We offer a continuous consulting and support service
                          that enables companies to achieve and maintain
                          compliance, safeguarding both their workers and
                          corporate interests.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6">
                <img
                  src="assets/img/services/service-3.jpeg"
                  className="w-100"
                  alt="team image"
                />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <img
                  src="assets/img/services/service-4.jpeg"
                  className="w-100"
                  alt="team image"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="bd-faq__wrapper bd-faq__padd2 mb-60">
                <div className="accordion" id="accordionExample2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
