import React from 'react'
import NewSlider from './NewSlider'

const Ourteamhome = () => {
  return (
    <>
      <section className="bd-cta__question pt-25">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="bd-cta__question-content text-center">
                <div className="bd-section__title mb-20">
                  <h2 className="bd__title">Our Team</h2>
                </div>
                <p className="mb-35 peramhfgfg">
                  Asian Institute of Safety's management success is attributable to our highly skilled, knowledgeable, and experienced team. Through their interactive Ideas and dedication to bringing those Ideas into practise, they enable us to survive in the competitive market and set us apart from the competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewSlider />
    </>
  )
}

export default Ourteamhome