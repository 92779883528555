import React from 'react'

const CourseHandbook = () => {
    return (
        <>

            <div style={{ width: "100%", height: "100%", display:"flex", justifyContent:"center",alignItems:"center",marginTop:"30px" }}>
              <a
               href="/assets/Nebosh Learner Course Handbook - Version 2.2.pdf"
               target="_blank"
              >
                <img src='/assets/pdf/Nebosh Learner Course Handbook _01.jpg' style={{ width: "100%", height: "100%", marginTop:"40px" }} />
              </a>
            </div>
        </>
    )
}

export default CourseHandbook