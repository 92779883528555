import React from 'react'

function Accompli() {
  return (
    <>
    <section className="bd-counter bd-counter1 theme-bg pt-20">
  <div className="container">
    <div className="row justify-content-center wow fadeInUp pt-20" data-wow-delay=".3s " >
      <div className="col-md-10">
        <div className="bd-section__title text-center">
          <h3 className="counthed">OUR ACCOMPLISHMENTS</h3>
          <p className="accomppp">At the Asian Institute of Safety Management we ensure each of our students receives training and
            education of the finest quality. With their dedication, the students achieve an extraordinary
            level of success due to that quality of learning. Our team's and our students' dedication has
            earned us a place at the top of India's learning providers.</p>
        </div>
      </div>
    </div>
    <div className="row wow fadeInUp" data-wow-delay=".3s">
      <div className="col-xl-3 col-lg-6 col-6">
        <div className="bd-counter bd-counter1__item text-center">
          <h2 className="bd-counter__title"><span className="counter">12</span><sup className="bd-counter__plus">+</sup>
          </h2>
          <span className="bd-counter__text">CERTIFIED TRAINERS</span>
          <div className="bd-counter__icon">
            <i className="flaticon-google-docs" />
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-6">
        <div className="bd-counter bd-counter1__item text-center">
          <h2 className="bd-counter__title"><span className="counter">1200</span><sup className="bd-counter__plus">+</sup>
          </h2>
          <span className="bd-counter__text">Certified Students </span>
          <div className="bd-counter__icon">
            <i className="flaticon-group" />
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-6">
        <div className="bd-counter bd-counter1__item text-center">
          <h2 className="bd-counter__title"><span className="counter">400</span><sup className="bd-counter__plus">+</sup>
          </h2>
          <span className="bd-counter__text">Course Batches</span>
          <div className="bd-counter__icon">
            <i className="flaticon-global" />
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-6">
        <div className="bd-counter bd-counter1__item text-center">
          <h2 className="bd-counter__title"><span className="counter">1000</span><sup className="bd-counter__plus">+</sup></h2>
          <span className="bd-counter__text">Placements </span>
          <div className="bd-counter__icon">
            <i className="flaticon-team" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Accompli