import React from "react";

const Gallery = () => {
  return (
    <>
      <section className="bd-team pt-80">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="col-lg-8">
              <div className="bd-section__title mb-45 text-center">
                <h2 className="bd__title">Gallery</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/1.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/2.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/3.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/4.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/5.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/6.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/7.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/8.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/9.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/10.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/11.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/12.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/13.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/14.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/15.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Gallery/16.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img
                      src="./assets/img/Gallery/gallaryimage2.jpeg"
                      alt="team img"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img
                      src="./assets/img/Gallery/gallaryimage3.jpeg"
                      alt="team img"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img
                      src="./assets/img/Gallery/gallaryimage4.jpeg"
                      alt="team img"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img
                      src="./assets/img/Gallery/gallaryimage5.jpeg"
                      alt="team img"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img
                      src="./assets/img/Gallery/gallaryimage1.jpeg"
                      alt="team img"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/1.jpeg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/2.jpeg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/90.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/2.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/3.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/3.jpeg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/6.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/14.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/20.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/22.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/23.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/24.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/25.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/63.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/73.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/87.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/95.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/features/104.jpg" alt="team img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img
                      src="./assets/img/features/out sid.jpg"
                      alt="team img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
