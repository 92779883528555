import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { contactPost } from "../redux/contact/ContactSlice";

const Contact = () => {

    const dispatch = useDispatch();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [textarea, setTextarea] = useState("");

  const [contactsubmit, setContactsubmit] = useState({});

  const ContactSubmit = (e) => {
    e.preventDefault();

    const formData = {
      firstname: String(firstname),
      lastname: String(lastname),
      mobile: String(mobile),
      email: String(email),
      text: String(textarea),
    };
    console.log(formData);
    dispatch(contactPost(formData));
    // alert("successfully")
    
  };

  return (
    <>
      <main>
        <section className="bd-case-details-area  pb-30">
          <div className="">
            <div
              className="bd-case-details-wrapper wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bd-case-thumb br-img-6 w-img">
                <img
                  src="assets/img/contact/contactus.jpeg"
                  style={{ maxHeight: "400px" }}
                  alt="case"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bd-contact__area pt-50 pb-20">
          <div className="container">
            <div className="bd-contact__item-wrapper mb-10">
              <div className="row ">
                <div className="col-lg-6 ">
                  <div className="bd-contact__item  white-bg-2 mb-20 card">
                    <h4 className="contact-info__title">Head Office</h4>
                    <p>
                      Asian Institute of Safety Management <br></br>
                      19-4-274/1/C/2/A, Opp Masjid-e-Mumtaz, BNK Colony, Tadban
                      X Road, Near Nehru Zoological Park, NH 7, Bahadurpura,
                      Hyderabad - 500064, Telangana State, India.
                    </p>
                    <a href="/cdn-cgi/l/email-protection#aac3c4ccc5eac8cbd9c3c9dec2cfc7cf84c9c5c7">
                      <span
                        className="__cf_email__"
                        data-cfemail="224b4c444d624043514b41564a474f470c414d4f"
                      >
                        info@aismtc.com
                      </span>
                    </a>
                    <a href="tel:8121066705">(+91) 8121066705 </a>
                    <a href="tel:9346066705">(+91) 9346066705 </a>
                    <a href="tel:040-24472416">(040) 24472416 </a>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="bd-contact__wrapper bd-contact__60 mb-60">
                    <div className="bd-section__title mb-50">
                      <span className="bd__subtitle">Contact Us</span>
                    </div>
                    <div className="bd-contact__form">
                      <form onSubmit={ContactSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="bd-single__input mb-20">
                              <input
                                type="text"
                                placeholder="First Name"
                                className="form-control"
                                required
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="bd-single__input mb-20">
                              <input
                                type="text"
                                placeholder="Last Name"
                                className="form-control"
                                required
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="bd-single__input mb-20">
                              <input
                                type="number"
                                placeholder="Phone Number"
                                className="form-control"
                                required
                                minLength={10}
                                maxLength={10}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="bd-single__input mb-20">
                              <input
                                type="email"
                                placeholder="Email Address"
                                className="form-control"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Type Your massage here"
                            name="text"
                            value={textarea}
                            onChange={(e) => setTextarea(e.target.value)}
                          ></textarea>

                          <div className="col-xl-6">
                            <div className="bd-single__input text-center">
                              <br />
                              <button type="submit" className="theme-btn">
                                Submit <i className="fal fa-arrow-alt-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="row justify-content-center mt-1">
                  <h3 className="text-center">Branch Offices</h3>
                </div>
                <div className="col-lg-6">
                  <div
                    className="bd-contact__item  white-bg-2"
                    style={{ height: "218px" }}
                  >
                    <h4 className="contact-info__title">Kerala Office</h4>
                    <p>
                      H. No. 67, Kalhaara South Girinagar Residence Association,
                      South Girinagar, Kadavanthra Kochi, Kerala – 682020
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="bd-contact__item  white-bg-2"
                    style={{ height: "218px" }}
                  >
                    <h4 className="contact-info__title">Mumbai Office</h4>
                    <p>
                      Office No. 126, First Floor, Seawoods Corner CHS Ltd.,
                      Plot No. 19/A, Sector 25, Near Seawoods Railway Station,
                      Nerul East,Navi Mumbai, Maharashtra, Pin Code: - 400706
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="bd-contact__img w-img br-img-6 mb-60">
                  <div className="mapouter">
                    <div className="">
                      <iframe
                        className="gmap_iframe"
                        frameBorder={0}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=600&height=400&hl=en&q=asian intitute of safety and managment,hyderabadf&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                      />
                    </div>
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          ".mapouter{position:relative;text-align:right;}.gmap_canvas {overflow:hidden;background:none!important;width:350px;height:350px;}.gmap_iframe {width:100%!important;height:350px!important;}",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Contact;
