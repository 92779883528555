import { configureStore } from "@reduxjs/toolkit";
import RegisterAllReducer from "./redux/register/RegisterSlice";
import BannerAllReducer from "./redux/banner/BannerSlice";
import NotificationAllReducer from "./redux/notification/NotificationSlice";


export const store = configureStore({
    reducer:{
        RegisterAll : RegisterAllReducer,
        BannerAll : BannerAllReducer,
        NotificationAll : NotificationAllReducer,
    }
})