import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {Baseurl} from "../../config/BaseUrl";

const initialState = {
    contactTotal:  [],
  
    contactTotalLoading: true,
  };
  

  export const contactPost = createAsyncThunk(
    "contact/contactPost",
    async (formData, thunkAPI) => {
      try {
          const config = {
              Headers: { "Content-Type": "application/json" },
            };
        const url = `${Baseurl}/api/v1/contact/new`;
        const resp = await axios.post(url, formData,config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("Contact Not create");
      }
    }
  );

  const ContactSlice = createSlice({
    name: "contactAll",
    initialState,
    extraReducers: {
      //contact post
      [contactPost.pending]: (state) => {
        state.contactTotalLoading = true;
      },
  
      [contactPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.contactTotal = [
            ...state.contactTotal,
            action.payload.contact,
          ].sort();
  
         
        }
        state.contactTotalLoading = false;
      },
  
      [contactPost.rejected]: (state, action) => {
        state.contactTotalLoading = true;
      },
    },
  });
  
  export default ContactSlice.reducer;