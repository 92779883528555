import React from 'react'
import { Link } from 'react-router-dom';

function Details() {
  return (
    <div>
      <section className="bd-faq bd-faq__padding-1 pt-2 p-relative">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-xl-12 col-lg-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="bd-faq__wrapper bd-faq__padd mb-60">
                <div className="bd-section__title mb-50">
                  {/* <span class="bd__subtitle">Faq</span> */}
                  <h2 className="bd__title-sm text-center">
                    Give Your Dream a Successful Beginning With AISM
                  </h2>
                </div>

                <div className="row ">
                  <div className="col-lg-6 col-md-6">
                    <div className="bd-services1__item mb-40  bg-light ">
                      <div className="bd-services1__icon srevice-img">
                        <img
                          src="assets/img/about/whower.jpeg"
                          alt="serivces icon"
                          className=" w-100"
                        />
                      </div>
                      <div className="bd-services1__text">
                        <h5 className="bd-services1__title text-center">
                          <a>Who We Are</a>
                        </h5>
                        <p className="bd-services1__para">
                          The Asian Institute of Safety Management is one of the
                          Best Safety course providers in India. and offered a
                          wide range of health and safety programs, including
                          international courses such as NEBOSH IGC and IOSH MS,
                          since 2006. and assisted thousands of students to
                          pursue careers in the health and safety industry.
                        </p>
                      </div>
                      {/* <div className="bd-services1__btn srevice-img">
                        <Link className="theme-btn" to="services">
                          Get This Service{" "}
                          <i className="fal fa-arrow-alt-right" />
                        </Link>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="bd-services1__item mb-40 bg-light ">
                      <div className="bd-services1__num"></div>
                      <div className="bd-services1__icon srevice-img">
                        <img
                          src="assets/img/about/whatwedo.jpeg"
                          alt="serivces icon"
                          className="w-100"
                        />
                      </div>
                      <div className="bd-services1__text">
                        <h5 className="bd-services1__title text-center">
                          <a>What We Do</a>
                        </h5>
                        <p className="bd-services1__para">
                          We evaluate and analyse each student's potential and
                          assist them focus on what they do best to achieve
                          their desired careers. We concentrate on the issues
                          and difficulties that the students face, and we then
                          transform those challenges and difficulties into very
                          understandable terms so that they can handle their
                          professional lives with confidence.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="bd-services1__item mb-40 bg-light ">
                      <div className="bd-services1__num"></div>
                      <div className="bd-services1__icon srevice-img">
                        <img
                          src="assets/img/about/ourspl.jpeg"
                          alt="serivces icon"
                          className="w-100"
                        />
                      </div>
                      <div className="bd-services1__text">
                        <h5 className="bd-services1__title text-center">
                          Our Speciality
                        </h5>
                        <p className="bd-services1__para">
                          Quality education in the best learning atmosphere in
                          that you can feel real-time experience while training
                          with us such as onsite training, practical sessions
                          and state of art classrooms. We constantly update our
                          training standards to reflect developments in the
                          industry. Our tutors are trained and qualified to
                          provide training in accordance with international
                          standards.
                        </p>
                      </div>
                      {/* <div className="bd-services1__btn srevice-img">
                        <Link className="theme-btn" to="services">
                          Get This Service{" "}
                          <i className="fal fa-arrow-alt-right" />
                        </Link>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="bd-services1__item mb-40  bg-light">
                      <div className="bd-services1__num"></div>
                      <div className="bd-services1__icon srevice-img">
                        <img
                          src="assets/img/about/whyus.jpeg"
                          alt="serivces icon"
                          className="w-100"
                        />
                      </div>
                      <div className="bd-services1__text">
                        <h5 className="bd-services1__title">Why US</h5>
                        <p className="bd-services1__para">
                          We will show the future vision of the students apart
                          from we will train them how to achieve better vision
                          using his interpersonal and intellectual skills.
                        </p>
                      </div>
                      {/* <div className="bd-services1__btn srevice-img">
                        <Link className="theme-btn" to="services">
                          Get This Service{" "}
                          <i className="fal fa-arrow-alt-right" />
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-lg-6">
                    <div className="card">
                      <h3 className="text-center mt-2">Who We Are</h3>

                      <div className="card-body">
                        <p>
                          The Asian Institute of Safety Management is one of the
                          Best Safety course providers in India. and offered a
                          wide range of health and safety programs, including
                          international courses such as NEBOSH IGC and IOSH MS,
                          since 2006. and assisted thousands of students to
                          pursue careers in the health and safety industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <h3 className="text-center mt-2">What We Do</h3>

                      <div className="card-body">
                        <p>
                          We evaluate and analyse each student's potential and
                          assist them focus on what they do best to achieve
                          their desired careers. We concentrate on the issues
                          and difficulties that the students face, and we then
                          transform those challenges and difficulties into very
                          understandable terms so that they can handle their
                          professional lives with confidence.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <h3 className="text-center mt-2">Our Speciality</h3>

                      <div className="card-body">
                        <p>
                          Quality education in the best learning atmosphere in
                          that you can feel real-time experience while training
                          with us such as onsite training, practical sessions
                          and state of art classrooms. We constantly update our
                          training standards to reflect developments in the
                          industry. Our tutors are trained and qualified to
                          provide training in accordance with international
                          standards.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <h3 className="text-center mt-2">Why US</h3>

                      <div className="card-body">
                        <p>
                          We will show the future vision of the students apart
                          from we will train them how to achieve better vision
                          using his interpersonal and intellectual skills.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Details