import React from "react";

function Course() {
  return (
    <>
      <section className="bd-case-details-area pt-120 pb-85">
        <div className="container">
          <div
            className="bd-case-details-wrapper wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="bd-case-thumb br-img-6 w-img">
              <img src="/assets/img/banner/sliderimg2.webp" alt="case" />
            </div>
            {/* <div className="bd-case-faq-wrapper mb-45 mt-45">
              <h4 className="bd__title-xs mb-10">
                some question &amp; answers
              </h4>
            </div> */}
          </div>
        </div>
      </section>
      <section className="bd-faq pt-12 pb-60 p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
              <div className="bd-faq__wrapper mb-60">
                <div className="accordion">
                  <div className="card">
                    <div className="row ">
                      <h3 className="text-left mt-3 px-5">SPECIAL PACKAGE:</h3>
                      <h3 className="text-left mt-3 px-5">
                        Includes’ 16 Certifications with Food and Accommodation
                      </h3>

                      <div className="col-lg-6 col-md-6 col-sm-6 mt-5">
                        <div className="cource-list">
                          <ul>
                            <li>
                              PG Diploma in Health, Safety and Environment.
                            </li>
                            <li>IOSH Managing Safely.</li>
                            <li>First Aid.</li>
                            <li>Train the Trainer.</li>
                            <li>Safety Audit.</li>
                            <li>Accident Investigation Training.</li>
                            <li>Work Place Hazard Identification.</li>
                            <li>Confined Space Safety Awareness Training.</li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mt-5">
                        <ul className="cource-list">
                          <li>Electrical Safety & Loto.</li>
                          <li>Scaffolding Safety Awareness Training.</li>
                          <li>Hazard Communication.</li>
                          <li>Risk Assessment.</li>
                          <li>Fire Safety Awareness Training.</li>
                          <li>Spoken English.</li>
                          <li>Spoken Arabic.</li>
                          <li>Diploma in Computer Applications.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 ">
                      <div className=" card">
                        <h5 className="text-left mt-3 px-4">
                          DIPLOMA’S PACKAGE-1:
                        </h5>
                        <h5 className="text-left mt-3 px-4">
                          Includes’ 3 Diplomas & Iosh Certifications
                        </h5>
                        <div>
                          <div className="cource-list">
                            <ul>
                              <li>
                                Diploma in Health, Safety and Environment.
                              </li>
                              <li>Diploma in Construction Safety.</li>
                              <li>Diploma in Industrial Safety. </li>
                              <li>Iosh – Managing Safely</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="card">
                        <h5 className="text-left mt-3 px-4">
                          DIPLOMA’S PACKAGE-2:
                        </h5>
                        <h5 className="text-left mt-3 px-4">
                          Includes’ 3 Diplomas & Iosh Certifications
                        </h5>
                        <div>
                          <div className="cource-list">
                            <ul>
                              <li>Diploma in Oil & Gas Safety.</li>
                              <li>Diploma in Construction Safety.</li>
                              <li>Diploma in Industrial Safety.</li>
                              <li>Iosh – Managing Safely</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="card">
                        <h5 className="text-left mt-3 px-4">
                          DIPLOMA’S PACKAGE-3:
                        </h5>
                        <h5 className="text-left mt-3 px-4">
                          Includes’ 3 Diplomas & Iosh Certifications
                        </h5>

                        <div>
                          <div className="cource-list">
                            <ul>
                              <li>Diploma in Environmental Safety</li>
                              <li>Diploma in Construction Safety.</li>
                              <li>Diploma in Industrial Safety.</li>
                              <li>Iosh – Managing Safely</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="card">
                        <h5 className="text-left mt-3 px-4">
                          DIPLOMA’ PACKAGE-4:
                        </h5>
                        <h5 className="text-left mt-3 px-4">
                          Includes’ 3 Diplomas & Iosh Certifications
                        </h5>
                        <div>
                          <div className="cource-list">
                            <ul>
                              <li>Diploma in Environmental Safety.</li>
                              <li>Diploma in Quality Control.</li>
                              <li>Diploma in Lead Auditing.</li>
                              <li>Iosh – Managing Safely</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Course;
