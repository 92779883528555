import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  bannerTotal: [],

  bannerTotalLoading: true,
};

export const getBanner = createAsyncThunk(
  "Banner/getBanner",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/banner/all`;
      const resp = await axios(url);
      return resp.data.banners;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const BannerSlice = createSlice({
  name: "BannerAll",
  initialState,
  extraReducers: {
    [getBanner.pending]: (state) => {
      state.bannerTotalLoading = true;
    },
    [getBanner.fulfilled]: (state, action) => {
      state.bannerTotal = action.payload;
      state.bannerTotalLoading = false;
      localStorage.setItem("bannerTotal", JSON.stringify(state.bannerTotal));
    },
    [getBanner.rejected]: (state) => {
      state.bannerTotalLoading = true;
    },
  },
});

export default BannerSlice.reducer;
