import React from "react";

function Aboutus() {
  return (
    <>
      <section className="bd-case-details-area pt-60">
        <div className="container">
          <div
            className="bd-case-details-wrapper wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="bd-case-thumb br-img-6 w-img">
              <img src="/assets/img/banner/sliderimg2.jpg" alt="case" />
            </div>
          </div>
        </div>
      </section>
      <section className="bd-cta__question pb-35">
        <div className="container">
          <div className="">
            <div className="">
              <div className="bd-cta__question-content text-center">
                <div className="bd-section__title mb-20">
                  <h2 className="bd__title">About Us</h2>
                </div>

                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <img
                      src="/assets/img/about/aboutpage.jpeg"
                      className=" w-100"
                    ></img>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <p className="mb-35 peramhfgfg">
                      Asian Institute of Safety Management is one of the best
                      health and safety course providers in India. It is
                      accredited by many international course providers,
                      including NEBOSH, IOSH, and others, and is approved by the
                      government of India.
                    </p>
                    <p className="mb-35 peramhfgfg">
                      Asian Institute of Safety Management was established in
                      2006, and over the duration of its more than 10 years of
                      existence, it has made impressive achievements and
                      sustained an integrated development of all services for
                      the needs of the students. We effectively shaped the
                      careers of thousands of students in the health and safety
                      industry. And we sustained our legacy of quality assurance
                      in the training process.
                    </p>
                    <p className="mb-35 peramhfgfg">
                      More than 80% of the students at the Asian Institute of
                      Safety Management were placed overseas, places such as
                      Australia, Poland, Croatia, New Zealand, Romania, Africa,
                      Qatar, the United Arab Emirates, Kuwait, Saudi Arabia,
                      Bahrain, Oman, and many more. They currently work for some
                      of the world's most well-known companies.
                    </p>
                    <p className="mb-35 peramhfgfg">
                      We aspire to provide the best services any institution or
                      organisation can provide and to assist you in any way we
                      can. Our students have access to the best resources and
                      facilities available to institutes, and we strive to make
                      their time here memorable. They will also have the chance
                      to meet new people because many students from around the
                      world come to study here throughout the year.
                    </p>
                    <p className="mb-35 peramhfgfg">
                      We are here to support you and make sure you have fun
                      while learning at the Institute. We can ensure you that
                      you are in excellent hands and are prepared for success.
                      Asian Institute of Safety Management is more than just a
                      Institution and it is here for you. It’s the way to a
                      better future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bd-contact-faq pb-60">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay=".3s">
            <div className="col-lg-6">
              <div className="bd-faq__wrapper bd-faq__padd2 mb-60">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <h3 className="text-center mt-2 btn-danger">MISSION</h3>

                    <div>
                      <div className="accordion-body">
                        <p>
                          Our objective is to prepare students for the
                          challenges and problems that come with working in
                          hazardous environments. Additionally, they may create
                          an environment at work that is accident-free, which
                          would be advantageous for both themselves and society.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h3 className="text-center mt-2 btn-danger">GOAL</h3>

                    <div>
                      <div className="accordion-body">
                        <p className="pb-20">
                          We strive to achieve the following goals in order to
                          fulfil the institution's mission, and we regularly
                          evaluate them to make sure our services and programs
                          are of the finest quality.
                        </p>
                        <p className="pb-20">
                          <b>Student Success -</b> Our first objective is
                          assisting every student in succeeding. In order to
                          fulfil our mission, it is essential for us to create
                          and increase educational opportunities and support
                          services. We are dedicated to encouraging a culture of
                          student success and to provide a setting that is
                          appropriate to teaching and learning.
                        </p>
                        <p className="pb-20">
                          <b> Excellence -</b> We are dedicated to upholding the
                          highest levels of excellence in all we do, and we stay
                          committed to this value by providing services and
                          programs that guarantee excellence in education and
                          learning, student services, and organization
                          performance.
                        </p>
                        <p>
                          <b>Career Programs Enhancement -</b> Provide career
                          programs that help students get ready for the
                          workforce and to fulfil employer needs. Analyse the
                          results of the students' learning at the course and
                          programme level. Examine and revise curriculums,
                          courses, and programs on a regular basis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bd-faq__wrapper bd-faq__padd2 mb-60">
                <div className="accordion" id="accordionExample2">
                  <div className="card">
                    <h3 className="text-center mt-2 btn-danger">VISION</h3>

                    <div>
                      <div className="accordion-body">
                        <p>
                          The Asian Institute of Safety Management aspires to
                          become a leading global safety education institution
                          by providing students with performance and
                          achievements at the diploma and postgraduate diploma
                          levels in the fields of construction, industry, oil
                          and gas requirements, and emerging technologies.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h2 className="accordion-header" id="headingFive">
                      <h3 className="text-center mt-2 btn-danger">
                        QUALITY POLICIES
                      </h3>
                    </h2>
                    <div>
                      <div className="accordion-body">
                        <p className="pb-20">
                          We strive to achieve the following goals in order to
                          carry out the institution's mission, and we regularly
                          evaluate them to make sure our services and programs
                          are of the finest quality.
                        </p>
                        <p className="pb-20">
                          The Institute is dedicated to provide high-quality,
                          practice-oriented education in order to produce
                          students who are knowledgeable, qualified, and
                          successful and who can meet the highest standards of
                          market demand.
                        </p>
                        <p className="pb-20">
                          The Institute is devoted to offering top-notch
                          infrastructure, resources, and personnel to meet both
                          the present and future needs of the customer and
                          stakeholders.
                        </p>
                        <p>
                          The Institute is dedicated to enhancing the Quality
                          Management System's efficacy throughout time.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutus;
