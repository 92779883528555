import React from 'react'
import $ from 'jquery'; 

window.jQuery = $;
const reviewspage = () => {
const map=window.map
    const google = window.google
    // This example requires the Places library. Include the libraries=places
// parameter when you first load the API. For example:
// <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&libraries=places">
window.onload = function () {
    initMap();
  };
  
  function initMap() {
    const request = {
      placeId: "ChIJ1w1HHeiXyzsRU8ZKBuOtPAI"
      // fields: ["name", "formatted_address", "place_id", "geometry"],
    };
    const infowindow = new google.maps.InfoWindow();
    const service = new google.maps.places.PlacesService(map);
    service.getDetails(request, (place, status) => {
      var json = place;
  
      //document.getElementById('TotalGoogleReviews').innerHTML = json.result.user_ratings_total
      // document.getElementById('TotalGoogelRatings').innerHTML = json.result.rating
  
      $.each(json.reviews, function (i, v) {
        var rating = v.rating;
        if (rating == 5) {
          var starRating =
            "<i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i>";
        } else if (rating == 4) {
          var starRating =
            "<i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i>";
        } else if (rating == 3) {
          var starRating =
            "<i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i>";
        } else if (rating == 2) {
          var starRating =
            "<i class='fa fa-star' aria-hidden='true'></i><i class='fa fa-star' aria-hidden='true'></i>";
        } else {
          var starRating = "<i class='fa fa-star' aria-hidden='true'></i>";
        }
  
        if (rating == 5) {
          $("#google-reviews").append(
            "<div class='review-wrap'>" +
              "<div class='author'>" +
              "<img class='author-img' src='" +
              v.profile_photo_url +
              "' alt='" +
              v.author_name +
              "' />" +
              "<div class='author-name'>" +
              v.author_name +
              "</div>" +
              "<div class='rating d-flex'>" +
              starRating +
              " " +
              v.relative_time_description +
              "</div>" +
              "</div>" +
              "<div class='review'>" +
              v.text +
              "</div>" +
              "<img class='googlerevirewimages' src='https://firebasestorage.googleapis.com/v0/b/aceso-webflow-project.appspot.com/o/Google-Review-Transparent.png?alt=media&token=bf57b74f-55eb-475e-91fc-096eb426d6aa' alt='" +
              v.author_name +
              "' />" +
              "</div>"
          );
        }
      });
    });
  }
  
  return (
    <>
    <div>Hii</div>
    <div id="map"></div>


<script
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBcv0QfUNUfBwo8pIGJ3teNCkaluSGUWus&callback=initMap&libraries=places&v=weekly"
  async
></script>

<div class="reviews-container">
<div id="google-reviews"></div>
</div>


    
    </>
  )
}

export default reviewspage