import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
  <div>
  <section className="bd-about bd-about1 wow fadeInUp" data-wow-delay=".3s">
    <div className="container">
      <div className="bd-about1__box">
        <div className="row align-items-center">
              <div className='col-xl-12 col-lg-12'>
                <img src="/assets/img/about/abouthome.jpeg" className=' w-100'></img>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div className="bd-about1__content-wrapper mt-10">
              <div className="bd-section__title mb-40">
                <span className="bd__subtitle" style={{marginBottom:"0px"}}>About Us</span>
                <h2 className="bd__title ls--3">Best Safety Management Training In Hyderabad</h2>
              </div>
          
              <p className="bd-about1__para mb-45">Asian Institute of Safety Management is one of the best health
                and safety course providers in India. It is accredited by many international course
                providers, including NEBOSH, IOSH, and others, and is approved by the government of India.
              </p>
              <p>
              Asian Institute of Safety Management was established in 2006, and over the duration of its more than 10 years of existence, it has made impressive achievements and sustained an integrated development of all services for the needs of the students. We effectively shaped the careers of thousands of students in the health and safety industry. And we sustained our legacy of quality assurance in the training process.
              </p>
              <div className="bd-about1__btn">
                <Link className="theme-btn" to="/aboutus">Read More <i className="fal fa-arrow-alt-right" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default About