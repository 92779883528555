import React from "react";
import { Link } from "react-router-dom";

function InteCourse() {
  return (
    <>
      <section className="bd-case-details-area pt-80 pb-30">
        <div className="container">
          <div
            className="bd-case-details-wrapper wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="bd-case-thumb br-img-6 w-img">
              <img src="/assets/img/banner/SliderImage1.jpg" alt="case" />
            </div>
          </div>
        </div>
      </section>
      <section class="bd-services3 grey-bg-3 pt-120 pb-90">
        <div class="container ">
          <div class="row wow fadeInUp" data-wow-delay=".5s">
            <div class="col-xl-6 col-lg-6 col-sm-6">
              <div class="bd-services3__item   mb-30 card">

                <h3 class="bd-services3__title">
                  <a href="https://www.nebosh.org.uk/home/" className="text-dark">
                    NEBOSH IGC
                  </a>
                </h3>

                <p class="bd-services3__price">
                  OBE NEBOSH International General Certificate
                </p>
                <p class="bd-services3__price">
                  Physical and Virtual Classroom, E- Learning
                </p>
                <div className="row justify-content-center">
                  <p>Click here for details</p>
                  <Link to="/nebosh"> <p class="bd-services3__price btn btn-danger w-50" >
                    In Just 34,999/- INR
                  </p></Link>
                </div>

              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-6 ">
              <div class="bd-services3__item   mb-30 card">

                <h3 class="bd-services3__title">
                  <a href="" className="text-dark">
                    IOSH MS
                  </a>
                </h3>
                <p class="bd-services3__price">
                  IOSH Managing Safety
                </p>

                <div className="row justify-content-center">
                  <p>
                    Click here for details
                  </p>
                  <Link to="/iosh"> <p class="bd-services3__price btn btn-danger w-50" >
                    In Just 10,000/- INR
                  </p> </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InteCourse;
