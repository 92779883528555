import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard,Autoplay } from "swiper";
import 'swiper/css';
import  'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
function IntPartner() {
  return (
    <>
<section className="bd-cta__question pt-125">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="bd-cta__question-content text-center">
                <div className="bd-section__title mb-20">
                  <h2 className="bd__title">OUR INTERNATIONAL PARTNERS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

<section className="bd-team pt-40 pb-20">
  <div className="container" >
   
    <Swiper
   grabCursor={true}
   navigation={false}
  //  pagination={true}
   mousewheel={false}
   keyboard={true}
  //  slidesPerView={6}
   spaceBetween={30}
   modules={[Navigation, Pagination, Mousewheel, Keyboard,Autoplay]}
   loop={true}
   autoplay={{
     delay:1500,
     disableOnInteraction : false
   }}
   breakpoints={{
    360: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    470: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    580: {
      slidesPerView: 2,
      spaceBetween: 4,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 4,
    },
    1080: {
      slidesPerView: 6,
      spaceBetween: 4,
    },
  }}
   className="ourteam mb-4 mx-3"
 >
    <SwiperSlide>
     <div className="outslidmain">
      <div className="imgteam">
      <img src="/assets/img/Gallery/part2.png" className="" width={180} height={170}  alt="invalid" />
      </div>
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/Gallery/part5.png" width={180} height={170}   alt="team" className="" />
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/Gallery/part3.png" width={180} height={170}   alt="team" className="" />
      </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/Gallery/part4.png" width={180} height={170}   alt="team" className=""  />
       
     </div>
     </SwiperSlide>
     <SwiperSlide>
     <div className="outslidmain">
      <img src="/assets/img/Gallery/part1.png" width={180} height={170}   alt="team" className="" />
       
     </div>
     </SwiperSlide>

 </Swiper>
  </div>
</section>



    </>
  );
}

export default IntPartner;
