import React from 'react'

function OurTeam() {
  return (
    <div>
      <section className="bd-case-details-area pt-40">
        <div className="container">
          <div
            className="bd-case-details-wrapper wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div
              className="bd-case-thumb br-img-6 w-img"
              style={{ border: "" }}
            >
              <img src="/assets/img/Gallery/temban.webp" alt="case" />
            </div>
          </div>
        </div>
      </section>

      <section className="bd-team pt-20">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="col-lg-8">
              <div className="bd-section__title mb-45 text-center">
                <span className="bd__subtitle"></span>
                <h2 className="bd__title">Our Management Team</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/101.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Samay Sayeed</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Managing Director
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/2.JFIF" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Rafay Abid</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Managing Director
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/3.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Wasay Touheed</a>
                  </h3>
                  <span className="bd-team__title mb-10">Admin Manager</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/4.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Jamey Juned</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Marketing Manager
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/5.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mirza Azhar Baig</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Business Development Manager
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/6.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Husna Jabeen</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Learning Adviser Head
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/7.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Syeda Rabiya Khan</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Course Coordinator
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/8.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Muhaimin Mastaan</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Office Coordinator
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/86.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Shaik Malik</a>
                  </h3>
                  <span className="bd-team__title mb-10">Administrator</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bd-team pb-80">
        <div className="container">
          <div
            className="row justify-content-center wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="col-lg-8">
              <div className="bd-section__title mb-45 text-center">
                <span className="bd__subtitle"></span>
                <h2 className="bd__title">Our Tutor Team</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/9.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>B Amrutha Sagaran</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Nebosh Authorized Head Tutor
                  </span>
                  {/* <div className="bd-team__social">
                <ul>
                  <li><a ><i className="fab fa-facebook-f" /></a></li>
                  <li><a ><i className="fab fa-twitter" /></a></li>
                  <li><a ><i className="fab fa-behance" /></a></li>
                  <li><a ><i className="fab fa-youtube" /></a></li>
                  <li><a ><i className="fab fa-linkedin" /></a></li>
                </ul>
              </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/10.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Anilkumar Prabhakaran</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Nebosh Authorized Tutor
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="./assets/img/Our Team/2.JFIF" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Rafay Abid</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Nebosh Authorized Tutor
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/11.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Unnikrishnan Arryattu Narayanan</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Nebosh Authorized Tutor
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/4.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Mohammed Abdul Jamey Juned</a>
                  </h3>
                  <span className="bd-team__title mb-10">Tutor</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/12.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Rahman Khan</a>
                  </h3>
                  <span className="bd-team__title mb-10">Tutor</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/13.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Khan Mateen Nisar Ahmed </a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Nebosh Authorized Tutor
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/14.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Maqdoom Muhiuddin</a>
                  </h3>
                  <span className="bd-team__title mb-10">Tutor</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/15.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Anish Kumar MP</a>
                  </h3>
                  <span className="bd-team__title mb-10">Tutor</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/16.jpg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Manesh GP</a>
                  </h3>
                  <span className="bd-team__title mb-10">Nebosh Authorized Tutor</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/17.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Sujilal Sadasivan</a>
                  </h3>
                  <span className="bd-team__title mb-10">Tutor</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="bd-team__item border-radius-6 mb-40">
                <div className="bd-team__thum w-img">
                  <a>
                    <img src="/assets/img/Our Team/18.jpeg" alt="team img" />
                  </a>
                </div>
                <div className="bd-team__content white-bg-2">
                  <h3 className="bd-team__name">
                    <a>Unnikrishnan Pazhaya Pisharath</a>
                  </h3>
                  <span className="bd-team__title mb-10">
                    Nebosh Authorized Tutor
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurTeam