import React from "react";

function Career() {
  return (
    <>
      <section className="bd-cta__question pt-65">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="bd-cta__question-content text-center">
                <div className="bd-section__title mb-20">
                  <h2 className="bd__title">Career</h2>
                </div>
                <p className="mb-35 peramhfgfg">
                  It is important to have a professional development plan after
                  you have a strong career and a clear understanding of the
                  direction you want to pursue in life. This will enable you to
                  succeed in the future and in your field of choice. We at the
                  Asian Institute of Safety Management are here to support you
                  in every aspect of your professional growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bd-contact2 p-relative">
        <div className="bd-contact2__img p-absolute">
          <img src="/assets/img/contact/img_01.jpg" className="rounded-3" alt="Contact-Image" />
        </div>
        <div className="container">
          <div
            className="row justify-content-end wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="col-lg-6">
              <div className="bd-contact2__wrapper mb-120">
                <div className="bd-contact__wrapper">
                  <div className="bd-contact__form">
                    <form action="#">
                      <div className="row">
                        <label className="mb-10">Full Name</label>
                        <div className="col-md-6">
                          <div className="bd-single__input mb-10">
                            <input type="text" placeholder="First Name" className="form-control"/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-single__input mb-10">
                            <input type="text" placeholder="Last Name" className="form-control"/>
                          </div>
                        </div>
                        <label className="mb-10">applying for position</label>
                        <div className="col-md-12">
                          <div className="bd-single__input mb-10">
                            <input type="text" placeholder="Simple Test" className="form-control"/>
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                          <div className="bd-single__input mb-10 col-md-12">
                            <select className="col-md-12 form-select">
                              <option selected disabled>
                               Select All
                              </option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                              <option></option>
                            </select>
                          </div>
                        </div> */}
                        <label className="mb-10">Phone Number</label>
                        <div className="col-md-12">
                          <div className="bd-single__input mb-20">
                            <input type="text" placeholder="Phone Number" className="form-control"/>
                          </div>
                        </div>
                        <label className="mb-10">Email Address</label>
                        <div className="col-md-12">
                          <div className="bd-single__input mb-20">
                            <input type="text" placeholder="Email Address" className="form-control"/>
                          </div>
                        </div>
                        <label className="mb-10">Upload your CV</label>
                        <div className="col-md-12">
                          <div className="bd-single__input mb-20">
                            <input type="File" className="form-control"/>
                          </div>
                        </div>
                        <label className="mb-10">Additional information</label>
                        <div className="col-md-12">
                          <div className="bd-single__input mb-20">
                            <input type="text" placeholder="Email Address" className="form-control"/>
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="bd-single__input">
                            <button type="submit" className="theme-btn">
                              Submit
                              <i className="fal fa-arrow-alt-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Career;
