import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const TextSlider = () => {
  const { notificationTotal } = useSelector((store) => store.NotificationAll);

  return (
    <div className="col-md-12 col-sm-12 col-lg-12 d-flex justify-content-center align-items-center">
      {notificationTotal.slice(0, 1).map((text, index) => (
        <marquee
          className="col-md-10 col-sm-12 col-lg-10 textslider"
          key={index}
        >
          {text.msg}
        </marquee>
      ))}
    </div>
  );
};

export default TextSlider;
