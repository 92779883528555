import React from "react";
import { Accordion } from "react-bootstrap";

const ReadOurPolicies = () => {
  return (
    <>
      <div className="container mt-lg-5">
        <section className="bd-faq p-relative">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Policy on Discontinuation , Complaints, Accessibility, and Refunds._page-0001 - Copy.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Policy on Discontinuation , Complaints,
                            Accessibility, and Refunds:
                            {/* Policy on Discontinuation, complaints policy: */}
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Investigation of Learner Non-Completion of Course Policy._page-0001 - Copy.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Investigation of Learner Non-Completion of Course
                            Policy:
                            {/* Investigation of learner Non-completion policy: */}
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Data Protection Policy_page-0001 - Copy.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Data Protection Policy:
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Annual Management and Quality Review Policy_page-0001.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Annual Management & Quality Review Policy:
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Equality and Disability Policy_page-0001.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Equality and Disability Policy:
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Health and Safety Policy_page-0001.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Health and Safety Policy:
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Student Complaints Policy_page-0001.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Student Complaints Policy:
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="bd-faq__wrapper mb-30">
                  <div className="accordion" id="">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="">
                        <a
                          href="assets/pdf/Recruitment and Development of Tutors Policy_page-0001 - Copy.jpg"
                          target="_blank"
                        >
                          <button className="accordion-button">
                            Recruitment and Development of Tutors Policy:
                            {/* Recruitment & development of tutor policy: */}
                          </button>
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default ReadOurPolicies;
