import { Navigation, Pagination, Mousewheel, Keyboard,Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function ServiceSlider() {
  return (
    <>
       <Swiper
      grabCursor={true}
      navigation={false}
      pagination={true}
      mousewheel={false}
      keyboard={true}
      modules={[Navigation, Pagination, Mousewheel, Keyboard,Autoplay]}
      loop={true}
      autoplay={{
        delay:2000,
        disableOnInteraction : false
      }}
      className="homeSlider"
    >
        <SwiperSlide>
        <div className="homeSliderImg">
        <img src="/assets/img/banner/SliderImage6.jpg" alt="banner"/>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="homeSliderImg">
        <img src="/assets/img/banner/SliderImage7.jpg" alt="banner"/>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="homeSliderImg">
        <img src="/assets/img/banner/SliderImage8.jpg" alt="banner"/>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="homeSliderImg">
        <img src="/assets/img/banner/SliderImage9.jpg" alt="banner"/>
        </div>
        </SwiperSlide>
    </Swiper>

    </>
  )
}

export default ServiceSlider