import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {Baseurl} from "../../config/BaseUrl";

const initialState = {
    registerAll: [],
    isregisterLoading: false,
  };

  export const getRegisterAll = createAsyncThunk(
    "RegisterAll/getRegisterAll",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/registration/all`;
        const resp = await axios.get(url);
        return resp.data.register;
      } catch (error) {
        return thunkAPI.rejectWithValue("member Not create");
      }
    }
  );


  export const getRegisterByCertificateNo = createAsyncThunk(
    "certificate/getRegisterByCertificateNo",
    async (certificateNo,thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/registration/getbycertificateNo/${certificateNo}`;
        const resp = await axios.get(url);
        return resp.data.register;
      } catch (error) {
        return error;
      }
    }
  );


  
const RegisterAll = createSlice({
    name:"RegisterAll",
    initialState,
    extraReducers:{
        [getRegisterAll.pending]:(state) =>{
            state.isregisterLoading = true;
        },
        [getRegisterAll.fulfilled]:(state,action) =>{
            state.registerAll = action.payload;

            state.isregisterLoading = false;
        },
        [getRegisterAll.rejected]:(state) =>{
            state.isregisterLoading = true;
        },
    }
})

export default RegisterAll.reducer;