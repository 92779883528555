import React from "react";
import { Link } from "react-router-dom";

function Nebosh() {
  return (
    <>
      <section className="bd-case-details-area pt-80 pb-30">
        <div className="container">
          <div
            className="bd-case-details-wrapper wow fadeInUp"
            data-wow-delay=".3s"
          >
            <div className="bd-case-thumb br-img-6 w-img">
              <img src="/assets/images/Slider Image 5.webp" alt="case" />
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <p>
              You can download our learner handbook for additional information
              about the course, which includes information about the course's
              duration, syllabus, eligibility, and more. As well as the NEBOSH
              official website for any specific information not contained in the
              learner handbook.
            </p>
          </div>
          <div class="container m-4" >
            <div class="col-md-12 text-center ">
              <Link
                target="_blank"
                // href="/assets/pdf/Nebosh Learner Course Handbook _01.jpg"
                to="/coursehandbook"

                // href="/assets/Nebosh Learner Course Handbook - Version 2.2.pdf"
                class="theme-btn red me-5"
                style={{fontSize:"14px"}} 
              >
              
                Download Learner Handbook 
              </Link>
              <Link to="/readOurPolicies" class="theme-btn red" style={{fontSize:"15px", marginLeft:"-43px"}}>
                AISM - Read Our Policies <i class="fal fa-arrow-alt-right"></i>
              </Link>
              <br />
              <br />
              <a
                target={"_blank"}
                href="https://www.nebosh.org.uk/home/"
                class="theme-btn red"
                style={{fontSize:"16px", marginLeft:"-43px"}}
              >
                Refer - Nebosh Website <i class="fal fa-arrow-alt-right"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <p>
              If you want to register yourself into the course, please schedule
              a counselling appointment by contacting our learning adviser. Our
              expert learning adviser will take you through the whole
              registration to certification process in detail. And advise you on
              the best options based on your qualifications and experience.
            </p>
          </div>
          <div class="container m-4">
            <div class="col-md-12 text-center ">
              <Link class="theme-btn red me-5" to="/contact">
                Contact Learning Advisor <i class="fal fa-arrow-alt-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>NEBOSH International General Certificate</h2>
            <br />
            <p>
              The NEBOSH International General Certificate is a Mandatory
              Certification for those who work or wish to work in the field of
              health, safety, and the environment. The certification would give
              you a broad understanding of health and safety issues. It will
              help you advance in your profession. The NEBOSH IGC is the most
              widely recognized health and safety certification in the world.
              Almost all employers in all sectors now want their safety
              professionals to be NEBOSH certified.
            </p>
          </div>
        </div>
      </section>

      <section className=" section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
              <div className="content">
                <h3>
                  The below are some of the advantages of earning the NEBOSH
                  International General Certificate:
                </h3>
              </div>
              <div className="accordion-list">
                <ul>
                  <li>
                    <span>01. </span>Obtain a role as a Safety Professional for
                    a Reputable Company.
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                    <span>02. </span> Meets the academic requirements for
                    Technical Membership of IOSH (Tech IOSH).
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                    <span>03. </span> Associate Membership in the IIRSM is
                    available to you (AIIRSM).
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                    <span>04. </span> Provides a solid foundation for the NEBOSH
                    International Diploma.
                    <i className="bx bx-chevron-down icon-show" />
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />

      <section className="bd-faq p-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 wow fadeInUp" data-wow-delay=".3s">
              <div className="bd-faq__wrapper mb-30">
                <div className="accordion" id="">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="">
                      <button className="accordion-button">
                        Why AISM is the Best and Most Successful Learning
                        Partner:
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          • Affordable learning Package: Just 34,999/- INR No
                          Hidden Cost - Including Registration Fee, Exam Fee,
                          Training Fee, Material and etc..
                        </p>
                        <br />
                        <p>
                          • An experienced career counsellor who can help you
                          not only with your studying but also with your future
                          plans.
                        </p>
                        <br />
                        <p>
                          • Authorized and experienced training professionals:
                          who are well qualified to make the learning process
                          smoother.
                        </p>
                        <br />
                        <p>
                          • Online / Virtual Classroom: Live Classes on Zoom /
                          Google Meet with Question answer Sessions.
                        </p>
                        <br />
                        <p>• Smart Classroom with all Learning Needs.</p>
                        <p>
                          • Flexible and adaptable learning time: Pre-recorded
                          training sessions are available at any time and from
                          any place. Comprehensive RRC Learning Material.
                          WhatsApp chat Support and many more
                        </p>
                        <br />
                        <p>
                          • Effective Method of Training: Providing you with the
                          most effective NEBOSH training possible. Individual
                          Learning, Group Learning, Mock Test, Subject Videos,
                          Presentations, Flashcards, and etc...{" "}
                        </p>
                        <br />
                        <p>
                          • Placements/Job Assistance: 1000’s of our Students
                          working as Safety professional all over the world. We
                          are a leading manpower recruitment consultant in India
                          and approved by the Ministry of External Affairs
                          (MEA), Government of India Registration No:
                          B-1070/TEL/PER/1000+/5/9402/2018 (Valid 12th Oct
                          2023).
                        </p>
                        <br />
                        <p>• 15 Years of Experience in the Industry. </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bd-work white-bg-2 pb-20"
        data-background="assets/img/work/work-bg.png"
      >
        <div className="bd-work__wrapper">
          <div className="container py-3">
            <div
              className="row justify-content-center wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div
                className="col-md-6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="bd-section__title text-center">
                  <h2 className=" btn-danger p-3">
                    Choose the best Education and Profession in one place
                  </h2>
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className="bd-section__title  text-left cource-list">
                  <ul>
                    <li>
                      High Quality Training with Practical Activities Expert
                      Trainer with International Experience
                    </li>
                    <li>Smart Classroom | High Standard Syllabus </li>
                    <li>Interactive and practical Learning Experience</li>
                    <li>Job Ready – 100% Placement Support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="bd-work__item-wrapper wow fadeInUp"
              data-wow-delay=".5s"
            ></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Nebosh;
