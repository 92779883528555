import React from 'react'
import About from '../Compund/Home/About'
import Accompli from '../Compund/Home/Accompli'
import Details from '../Compund/Home/Details'
import Header from '../Compund/Home/Header'
import HomeSlider from '../Compund/Home/HomeSlider'
import IntPartner from '../Compund/Home/IntPartner'
import Ourteamhome from '../Compund/Home/Ourteamhome'
import Services from '../Compund/Home/Services'
import Testimals from '../Compund/Home/Testimals'
import TextSlider from '../Compund/Home/TextSlider'



function Home() {
  return (
    <>
      <TextSlider />
      <HomeSlider />
      <Details />
      <About />
      <Services />
      <Ourteamhome />
      <Testimals />
      <Accompli />
      <IntPartner />
      
    </>
  );
}

export default Home