import "./App.css";
import {
  Route,
  Routes,
  BrowserRouter,
  HashRouter,
  useLocation,
} from "react-router-dom";
import Mainlayout from "./Compund/Layout/Mainlayout";
import Home from "./pages/Home";
import Services from "./pages/Services";
import OurTeam from "./pages/OurTeam";
import InteCourse from "./pages/InteCourse";
import Directoremass from "./pages/Directoremass";
import Course from "./pages/Course";
import Career from "./pages/Career";
import Alumini from "./pages/Alumini";
import Aboutus from "./pages/Aboutus";
import Iosh from "./pages/Iosh";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Reviewspage from "./pages/Reviewspage";
import { Link } from "react-scroll";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getRegisterAll,
  getRegistration,
} from "./redux/register/RegisterSlice";
import { getBanner } from "./redux/banner/BannerSlice";
import { getNotification } from "./redux/notification/NotificationSlice";
import ReadOurPolicies from "./pages/ReadOurPolicies";
import Nebosh from "./pages/Nebosh";
import CourseHandbook from "./pages/CourseHandbook";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(getRegisterAll());
      } catch (error) {}
    };
    initialSetup();

    dispatch(getBanner());
    dispatch(getNotification());
  }, []);

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(100, 100);
    }, [pathname]);

    return null;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Mainlayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/ourteam" element={<OurTeam />} />
            <Route path="/international-course" element={<InteCourse />} />
            <Route path="/directoremass" element={<Directoremass />} />
            <Route path="/course" element={<Course />} />
            <Route path="/career" element={<Career />} />
            <Route path="/alumini" element={<Alumini />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/iosh" element={<Iosh />} />
            <Route path="/nebosh" element={<Nebosh />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/review" element={<Reviewspage />} />
            <Route path="/readOurPolicies" element={<ReadOurPolicies />} />
          </Route>
          <Route path="/coursehandbook" element={<CourseHandbook />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
