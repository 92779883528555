import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Testimals() {
  return (
    <>
      <section
        className="bd-tm bd-tm--space pb-10"
        data-background="assets/img/testimonial/map-bg.png"
      >
        <div className="container">
          <div className="bd-tm-tittle-wrapper">
            <div
              className="row align-items-center wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="col-sm-8">
                <div className="bd-section__title bd-section__tm-title">
                  <span className="bd__subtitle">Testimonials</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bd-tm__slider__area fix wow fadeInUp"
          data-wow-delay=".5s"
        >
          <div className="container">
            <Swiper
              grabCursor={true}
              navigation={false}
              //  pagination={true}
              mousewheel={false}
              keyboard={true}
              //  slidesPerView={6}
              spaceBetween={30}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              loop={true}
              // autoplay={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                470: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                580: {
                  slidesPerView: 1,
                  spaceBetween: 4,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 4,
                },
                1080: {
                  slidesPerView: 2,
                  spaceBetween: 4,
                },
              }}
              className="ourteam mb-4 mx-3"
            >
              <SwiperSlide>
                <div
                  className="outslidmain"
                  style={{
                    border: " 2px solid #e4e4e4",
                    padding: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    borderRadius: "25px",
                    backgroundColor: "#e4e4e4",
                  }}
                >
                  <div className="imgteam d-flex align-content-center align-items-center pb-10 ">
                    <img
                      src="https://lh3.googleusercontent.com/a-/ACB-R5Sj2Q3JnmNraZu6Mfk6IcCe79effei8uNCXeHFWJQ=s40-c-c0x00000000-cc-rp-mo-br100"
                      className="rounded-circle"
                      width={50}
                      height={50}
                      alt="invalid"
                      style={{ border: "1px solid #e4e4e4" }}
                    />
                    <div className="pl-10">
                      <h5
                        className="pl-10 text-left"
                        style={{
                          fontWeight: "600",
                          fontFamily: "sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        Shaik Bilal
                      </h5>
                      <p className=" pl-10" style={{ color: "#f0d62e" }}>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="text_review">
                    <p className="  mb-0">
                      I’m Shaik Bilal Uddin I have attended the 6th July Obe
                      examination 2022 and taken the virtual online classes. It
                      was very good learning experience with Asian Institute of
                      Safety Management. I have passed in first attempt with
                      distinction marks.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="outslidmain"
                  style={{
                    border: " 2px solid #e4e4e4",
                    padding: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    borderRadius: "25px",
                    backgroundColor: "#e4e4e4",
                  }}
                >
                  <div className="imgteam d-flex align-content-center align-items-center pb-10 ">
                    <img
                      src="https://lh3.googleusercontent.com/a-/ACB-R5SEw6lAazKPKakREHh0IMsCSYcCqinPv9Evnf7d=s40-c-c0x00000000-cc-rp-mo-br100"
                      className="rounded-circle"
                      width={50}
                      height={50}
                      alt="invalid"
                      style={{ border: "1px solid #e4e4e4" }}
                    />
                    <div className="pl-10">
                      <h5
                        className="pl-10 text-left"
                        style={{
                          fontWeight: "600",
                          fontFamily: "sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        Mohammed Raphy
                      </h5>
                      <p className=" pl-10" style={{ color: "#f0d62e" }}>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="text_review">
                    <p className="  mb-0">
                      I recently completed NEBOSH IGC and would like to thanks.
                      Asian Institute of Safety Management for their throughout
                      support during my Course NEBOSH IGC. Especially the
                      Trainer Mr. Anil Kumar Sir and the Coordinator Ms. Husna
                      Madam with their highly professionalism and dedication
                      towards their learners.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="outslidmain"
                  style={{
                    border: " 2px solid #e4e4e4",
                    padding: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    borderRadius: "25px",
                    backgroundColor: "#e4e4e4",
                  }}
                >
                  <div className="imgteam d-flex align-content-center align-items-center pb-10 ">
                    <img
                      src="https://lh3.googleusercontent.com/a-/ACB-R5SScWMW8RnDPunpGp3667wSih6L-wtlw9-7nCUodA=s40-c-c0x00000000-cc-rp-mo-br100"
                      className="rounded-circle"
                      width={50}
                      height={50}
                      alt="invalid"
                      style={{ border: "1px solid #e4e4e4" }}
                    />
                    <div className="pl-10">
                      <h5
                        className="pl-10 text-left"
                        style={{
                          fontWeight: "600",
                          fontFamily: "sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        Balaji Vasala
                      </h5>
                      <p className=" pl-10" style={{ color: "#f0d62e" }}>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="text_review">
                    <p className="  mb-0">
                      I wrote exam NEBOSH IGC in Asian, Professors were
                      excellent they give well explanation and covered each
                      topic importance in the exam. I have completed NEBOSH IGC
                      examination with credit marks on 8th June 2022
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="outslidmain"
                  style={{
                    border: " 2px solid #e4e4e4",
                    padding: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    borderRadius: "25px",
                    backgroundColor: "#e4e4e4",
                  }}
                >
                  <div className="imgteam d-flex align-content-center align-items-center pb-10 ">
                    <img
                      src="https://lh3.googleusercontent.com/a-/ACB-R5SQgp2qI1GfNxU-prvjWIPLkQgcrIGHamuursuZrQ=s40-c-c0x00000000-cc-rp-mo-br100"
                      className="rounded-circle"
                      width={50}
                      height={50}
                      alt="invalid"
                      style={{ border: "1px solid #e4e4e4" }}
                    />
                    <div className="pl-10">
                      <h5
                        className="pl-10 text-left"
                        style={{
                          fontWeight: "600",
                          fontFamily: "sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        Md.Haseeb uddin.
                      </h5>
                      <p className=" pl-10" style={{ color: "#f0d62e" }}>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>
                        <span>
                          <i class="fa fa-star pr-5" aria-hidden="true"></i>
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="text_review">
                    <p className="  mb-0">
                      I have received the greatest benefit of anything I can
                      imagine from Asian Institute of safety Management to build
                      my carrier. All of this will defiantly contribute to my
                      career. The way you trained me administratively,
                      emotionally, academically, friendly to become competent
                      safety professional has been really tremendous.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimals;
