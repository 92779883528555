import React from 'react'
import { Link } from 'react-router-dom'

function Services() {
  return (
   <div>
  <section className="bd-services bd-services1 grey-bg-2 pt-100 pb-20">
    <div className="container">
      <div className="row justify-content-center wow fadeInUp" data-wow-delay=".3s">
        <div className="col-md-7">
          <div className="bd-section__title text-center mb-45">
            <h2 className="bd__subtitle" style={{fontSize:38}}>Services</h2>
          
          </div>
        </div>
      </div>
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            
        <div className="col-lg-4 col-md-6">
          <div className="bd-services1__item mb-40">
           
            <div className="bd-services1__icon srevice-img">
              <img src="/assets/img/services/health.jpg" alt="serivces icon" />
            </div>
            <div className="bd-services1__text">
              <h5 className="bd-services1__title"><a >Health and Safety Course
                  Provider you</a></h5>
              <p className="bd-services1__para">Diploma and PG Diploma</p>
              <p className="bd-services1__para">Short Term Training Courses</p>
            </div>
            <div className="bd-services1__btn srevice-img">
              <Link className="theme-btn" to='services'>Get This Service <i className="fal fa-arrow-alt-right" /></Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="bd-services1__item mb-40">
            <div className="bd-services1__num">
             
            </div>
            <div className="bd-services1__icon srevice-img">
              <img src="assets/img/services/offshore.png" alt="serivces icon" />
            </div>
            <div className="bd-services1__text">
              <h5 className="bd-services1__title"><a >Offshore HSE Course Provider</a>
              </h5>
              <p className="bd-services1__para">Sea Survival Courses</p>
              <p className="bd-services1__para">Boiset Courses</p>
              <p className="bd-services1__para">OPITO Approved Courses</p>
            </div>
            <div className="bd-services1__btn srevice-img">
              <Link className="theme-btn" to='services' >Get This Service <i className="fal fa-arrow-alt-right" /></Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="bd-services1__item mb-40">
            <div className="bd-services1__num">
             
            </div>
            <div className="bd-services1__icon srevice-img">
              <img src="/assets/img/services/onsite.png" alt="serivces icon" />
            </div>
            <div className="bd-services1__text">
              <h5 className="bd-services1__title"><a >On Site Training Provider</a>
              </h5>
              <p className="bd-services1__para">HSE Awareness Trainings</p>
              <p className="bd-services1__para">Fire and Safety Trainings</p>
              <p className="bd-services1__para">First Aid Trainings</p>
            </div>
            <div className="bd-services1__btn srevice-img">
              <Link className="theme-btn" to="services" >Get This Service <i className="fal fa-arrow-alt-right" /></Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="bd-services1__item mb-40">
            <div className="bd-services1__num">
           
            </div>
            <div className="bd-services1__icon srevice-img">
              <img src="/assets/img/services/online.jpg" alt="serivces icon" />
            </div>
            <div className="bd-services1__text">
              <h5 className="bd-services1__title"><a >Online Course Provider</a></h5>
              <p className="bd-services1__para">HSE Courses </p>
              <p className="bd-services1__para">Nebosh IGC</p>
            </div>
            <div className="bd-services1__btn srevice-img">
              <Link className="theme-btn" to="services" >Get This Service <i className="fal fa-arrow-alt-right" /></Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="bd-services1__item mb-40">
           
            <div className="bd-services1__icon srevice-img">
              <img src="/assets/img/services/interna.png" alt="serivces icon" />
            </div>
            <div className="bd-services1__text">
              <h5 className="bd-services1__title"><a>International Course
                  Provider</a></h5>
              <p className="bd-services1__para">Nebosh IGC</p>
              <p className="bd-services1__para">IOSH MS</p>
            </div>
            <div className="bd-services1__btn srevice-img">
              <Link  to="services" className="theme-btn">Get This Service <i className="fal fa-arrow-alt-right" /></Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="bd-services1__item mb-40">
           
            <div className="bd-services1__icon srevice-img">
              <img src="/assets/img/services/oilandga.png" alt="serivces icon" />
            </div>
            <div className="bd-services1__text">
              <h5 className="bd-services1__title"><a >Oil and Gas HSE Course
                  Provider</a></h5>
              <p className="bd-services1__para">Diploma and PG Diploma in O&amp;G</p>
              <p className="bd-services1__para">Training Courses in O&amp;G</p>
            </div>
            <div className="bd-services1__btn srevice-img">
              <Link to="/services" className="theme-btn" >Get This Service <i className="fal fa-arrow-alt-right" /></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default Services