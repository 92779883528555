import React from 'react';
import { Outlet } from "react-router-dom";
import Header from '../Home/Header';
import Footer from './Footer';

function Mainlayout() {
  return (
    <>
    <Header />
    <Outlet />
    <Footer />
    </>
  )
}

export default Mainlayout