import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  notificationTotal: [].slice().reverse(),

  notificationLoading: true,
};

export const getNotification = createAsyncThunk(
  "Notification/getNotification",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/notification/all`;
      const resp = await axios(url);
      return resp.data.notifications;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const NotificationSlice = createSlice({
  name: "NotificationAll",
  initialState,
  extraReducers: {
    [getNotification.pending]: (state) => {
      state.notificationLoading = true;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.notificationTotal = action.payload.slice().reverse();
      state.notificationLoading = false;
   
      localStorage.setItem(
        "notificationTotal",
        JSON.stringify(state.notificationTotal)
      );
    },
    [getNotification.rejected]: (state, action) => {
      state.notificationLoading = true;
    },
  },
});

export default NotificationSlice.reducer;
