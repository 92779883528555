import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [toggle, setToggle] = useState(0);
  const [toggle1, setToggle1] = useState(0);

  const myshow = () => {
    setToggle(1);
    document.getElementById("mobmenu").style.display = "block";
  };
  const myhide = () => {
    setToggle(0);
    document.getElementById("mobmenu").style.display = "none";
  };

  const show = () => {
    setToggle1(1);
    document.getElementById("menu").style.display = "block";
  };
  const hide = () => {
    setToggle1(0);
    document.getElementById("menu").style.display = "none";
  };
  return (
    <div>
      <div className="bd-header__position">
        <div className="bd-header__wrapper p-relative">
          <div className="bd-header__top bd-header__top1 d-none d-xl-block">
            <div
              className=""
              style={{ margin: "3px 10px", overflow: "hidden" }}
            >
              <div className="row justify-content-end">
                <div className=" col-lg-2 col-md-2">
                  <div className="logo logo-position d-none d-xl-block">
                    <Link to="/">
                      <img
                        src="/assets/img/logo/aismlogo-c.jpg"
                        className="logo-img"
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className=" col-lg-10 col-md-10">
                  <div className="bd-header__top1-wrapper d-flex justify-content-between align-items-center">
                    <div
                      className="bd-header__top1-info-wrapper d-flex"
                      style={{ paddingTop: 3 }}
                    >
                      <div className="bd-header__top1-info">
                        <div className="bd-header__top1-info-icon">
                          <i className="flaticon-telephone-call" />
                        </div>
                        <div className="bd-header__top1-info-text">
                          <span className="bd-header__top1-sub-title">
                            Call Us Now
                          </span>
                          <h4 className="bd-header__top1-title">
                            <a
                              href="tel:+91-8121066705"
                              style={{ color: "#fff", fontSize: "14px" }}
                            >
                              +91-8121066705
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="bd-header__top1-info">
                        <div className="bd-header__top1-info-icon">
                          <i className="flaticon-envelope" />
                        </div>
                        <div className="bd-header__top1-info-text">
                          <span className="bd-header__top1-sub-title">
                            Email Address
                          </span>
                          <h4 className="bd-header__top1-title">
                            <a
                              href="mailto:info@aismtc.com"
                              style={{ color: "#fff", fontSize: "14px" }}
                              target="_blank"
                            >
                              <span
                                className="__cf_email__"
                                data-cfemail="2b42454d446b5c4e49464a424705484446"
                              >
                                info@aismtc.com
                              </span>
                            </a>
                          </h4>
                        </div>
                      </div>
                      {/* <div className="bd-header__top1-info">
                        <div className="bd-header__top1-info-icon">
                          <i className="flaticon-map-pin" />
                        </div>
                        <div className="bd-header__top1-info-text">
                          <span className="bd-header__top1-sub-title">
                            Office Address
                          </span>
                          <h4 className="bd-header__top1-title">
                            <a style={{ color: "#fff", fontSize:"14px" }}>BNK Colony,Hyd</a>
                          </h4>
                        </div>
                      </div> */}
                      <div className="bd-header__top1-info">
                        {/* <div className="bd-header__top1-info-icon">
                          <i className="flaticon-map-pin" />
                        </div> */}
                        <div className="bd-header__top1-info-text">
                          <span className="bd-header__top1-sub-title">
                            License Number
                          </span>
                          <h4 className="bd-header__top1-title">
                            <a style={{ color: "#fff", fontSize: "13px" }}>
                              An ISO 9001: 2008 Certified
                              <br />
                              Govt A.P LR.No:j1/52163, ROF. 2740
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="bd-header__top1-info">
                        {/* <div className="bd-header__top1-info-icon">
                          <i className="flaticon-map-pin" />
                        </div> */}
                        <div className="bd-header__top1-info-text text-center">
                          <span className="bd-header__top1-sub-title ">
                            Social Link
                          </span>
                          <h4 className="bd-header__top1-title">
                            <a style={{ color: "#fff", fontSize: "14px" }}>
                              <div className="">
                                <div className="bd-footer-social mb-20">
                                  <ul>
                                    <li>
                                      <a
                                        href="https://www.facebook.com/AISMTC"
                                        target="_blank"
                                      >
                                        <i className="fab fa-facebook" />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://www.instagram.com/asianmanpowerservices/?hl=en"
                                        target="_blank"
                                      >
                                        <i className="fab fa-instagram" />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://in.linkedin.com/school/asian-institute-of-safety-management/"
                                        target="_blank"
                                      >
                                        <i className="fab fa-linkedin" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-2 col-md-2">
                  <div className="logo logo-position d-none d-xl-block">
                    <Link to="/">
                      <img
                        src="/assets/img/logo/logo3.jpeg"
                        className="logo-img"
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bd-header__bottom bd-header__bottom1 bd-header__transparent ">
            <div className="bd-header__bottom-wrapper ">
              <div className="container">
                <div className="bd-header__content white-bg">
                  <div className="row justify-content-end">
                    <div className="col-xl-12 col-lg-12">
                      <div className="d-flex justify-content-between align-items-center bd-header__bottom-order">
                       
                        <div className="main-menu main-menu1">
                          <nav id="mobile-menu">
                            <ul>
                              <li>
                                <Link to="/">Home</Link>
                              </li>
                              <li>
                                <Link to="/aboutus">About Us</Link>
                                <ul>
                                  <li>
                                    <Link to="/directoremass">
                                      Director's Message
                                    </Link>{" "}
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="/services">services</Link>
                              </li>
                              <li>
                                <Link to="/ourteam">Our Team</Link>
                              </li>
                              <li>
                                <Link to="/international-course">
                                  International Courses
                                </Link>
                                <ul>
                                  <li>
                                    <Link to="/nebosh">nebosh IGC</Link>
                                  </li>
                                  <li>
                                    <Link to="/iosh">IOSH MS</Link>
                                  </li>
                                </ul>
                              </li>

                              <li>
                                <Link to="/course">Courses</Link>
                              </li>
                              <li>
                                <Link to="/alumini">Alumini</Link>
                              </li>
                              <li>
                                <Link to="/career">Career</Link>
                              </li>
                              <li>
                                <Link to="/gallery">Gallery</Link>
                              </li>
                              <li>
                                <Link to="/contact">contact Us</Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bd-mobile__header bd-mobile__header-padding  d-block d-lg-none">
            <div className="container">
              <div
                className="row align-items-center"
                style={{ overflow: "hidden" }}
              >
                <div className="col-10">
                  <div className="row bd-mobile__logo">
                    <div className="col-6">
                      <Link to="/">
                        <img
                          src="/assets/img/logo/aismlogo-c.png"
                          className="logo-img"
                          alt="Logo"
                        />
                      </Link>
                    </div>

                    <div className="col-6" >
                      <Link to="/">
                        <img
                          src="/assets/img/logo/logo4.jpeg"
                          className="logo-img"
                          alt="Logo"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="header__toggle-btn sidebar-toggle-btn text-end d-block d-lg-none mt-10">
                    {toggle1 === 0 ? (
                      <div className="header__bar" onClick={show}>
                        <span />
                        <span />
                        <span />
                      </div>
                    ) : (
                      <div className="header__bar" onClick={hide} style={{fontSize:"30px", fontFamily:"sans-serif", color:"black" }}>
                        <b>X</b>
                      </div>
                    )}

                    <nav className="mobileNav" style={{textShadow:"1px 1px 1px #86b7fe"}}>
                      <ul
                        className="Firstnav"
                        id="menu"
                        style={{ display: "none" }}
                      >
                        <li style={{textUnderlineOffset:"50px"}}>
                          <Link className="link" to="/" onClick={hide} style={{textUnderlineOffset:"50px"}}>
                            Home
                          </Link>
                          
                        </li>
                        <li>
                          <Link className="link" to="/aboutus" onClick={hide}>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link className="link" to="/services" onClick={hide}>
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link className="link" to="/ourteam" onClick={hide}>
                            Our Team
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="link"
                            to="/international-course"
                            onClick={hide}
                          >
                            International Courses
                          </Link>
                          <ul>
                            <li>
                              <Link
                                className="link"
                                to="/ourteam"
                                onClick={hide}
                              >
                                Course
                              </Link>
                            </li>
                            <li>
                              {/* <Link className="link" to="international-course" onClick={hide}>
                                International Course
                              </Link> */}
                            </li>
                            <li>
                              <Link
                                className="link"
                                to="/nebosh"
                                onClick={hide}
                              >
                                nebosh IGC
                              </Link>
                            </li>
                            <li>
                              <Link className="link" to="/iosh" onClick={hide}>
                                IOSH MS
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link className="link" to="/alumini" onClick={hide}>
                            Alumini
                          </Link>
                        </li>
                        <li>
                          <Link className="link" to="/career" onClick={hide}>
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link className="link" to="/gallery" onClick={hide}>
                            Gallery
                          </Link>
                        </li>
                        <li>
                          <Link className="link" to="/contact" onClick={hide}>
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
